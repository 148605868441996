<template>
  <div>
    <div class="row mt-2 mb-4">
      <div class="input-group col-lg-6 align-content-center">
        <span class="input-group-prepend">
          <b-button class="btn btn-sm btn-primary" @click="clearFilter">{{ $t('common.actions.clear') }}</b-button>
        </span>
        <b-form-input
          type="text"
          debounce="300"
          max="50"
          :placeholder="$t('enterForSearch')"
          v-model="table.filter"
          class="form-control form-control-sm"
        />
        <span class="input-group-append">
          <b-button class="btn btn-sm btn-primary" @click="refreshAnalyses">
            <i class="fas fa-refresh mr-1"></i>
            <span>{{ $t('common.actions.refresh') }}</span>
          </b-button>
        </span>
      </div>
      <div class="col-lg-3 m-t-sm text-right" v-if="processedAnalyses">
        <row-count :size="table.size" :page="table.page" :total="processedAnalyses.length"></row-count>
      </div>
      <div class="col-lg-3 text-right" v-if="processedAnalyses && processedAnalyses.length && processedAnalyses.length > table.size">
        <b-pagination
          size="sm"
          class="m-t-xs"
          align="right"
          :total-rows="processedAnalyses.length"
          v-model="table.page"
          :per-page="table.size"
        ></b-pagination>
      </div>
    </div>

    <div v-if="processedAnalyses && processedAnalyses.length">
      <div class="row mt-2 mb-2">
        <div class="col-lg-12">
          <b-table
            responsive
            hover
            striped
            class="text-nowrap"
            thead-class="text-nowrap"
            :filter="table.filter"
            :fields="table.fields"
            :current-page="table.page"
            :per-page="table.size"
            :items="processedAnalyses"
            :sort-by.sync="table.sortBy"
            :sort-desc.sync="table.sortDesc"
            @row-clicked="openAnalysisModal"
          >
            <template #cell(name)="data">
              <span>{{ data.item.name }}</span>
            </template>
            <template #cell(progress)="data">
              <span>{{ data.item.progress }}%</span>
            </template>
            <template #cell(reportingFrom)="data">
              <span>{{ data.item.reportingFrom }}</span>
            </template>
            <template #cell(reportingTo)="data">
              <span>{{ data.item.reportingTo }}</span>
            </template>
            <template #cell(totalPrediction)="data">
              <span>{{ data.item.totalPrediction }}</span>
            </template>
            <template #cell(totalActual)="data">
              <span>{{ data.item.totalActual }}</span>
            </template>
            <template #cell(totalLowerBound)="data">
              <span>{{ data.item.totalLowerBound }}</span>
            </template>
            <template #cell(totalUpperBound)="data">
              <span>{{ data.item.totalUpperBound }}</span>
            </template>
            <template #cell(actions)="data">
              <span class="icon-wrapper">
                <span @click.stop="deleteAnalysis(data.item)" class="delete-link">
                  <i class="fa-regular fa-trash-can"></i>
                </span>
              </span>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

    <div>
      <b-button @click="showModal = true" class="mb-4" variant="primary">
        <i class="fa fa-plus"></i>&nbsp;{{ $t('energySavings.newAnalysis') }}
      </b-button>
    </div>

    <b-modal size="xl" v-model="showModal" :title="$t('energySavings.newAnalysis')" hide-footer>
      <new-energy-savings-analysis @close="showModal = false" @success="orderAnalysisSuccessCallback" />
    </b-modal>

    <b-modal size="xl" v-model="showAnalysisModal" :title="$t('energySavings.title')" hide-footer>
      <energy-savings-analysis-details :analysis="selectedAnalysis" @close="showAnalysisModal = false" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NewEnergySavingsAnalysis from '@/components/views/energySavings/NewEnergySavingsAnalysis.vue';
import EnergySavingsAnalysisDetails from '@/components/views/energySavings/EnergySavingsAnalysisDetails.vue';
import RowCount from '@/components/common/RowCount';

export default {
  components: {
    NewEnergySavingsAnalysis,
    EnergySavingsAnalysisDetails,
    RowCount,
  },
  props: {
    building: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      showAnalysisModal: false,
      selectedAnalysis: null,
      table: {
        filter: '',
        fields: [
          { key: 'name', label: this.$t('energySavings.analysisName'), sortable: true },
          { key: 'progress', label: this.$t('energySavings.progress'), sortable: true },
          { key: 'reportingFrom', label: this.$t('energySavings.reportingStartDate'), sortable: true },
          { key: 'reportingTo', label: this.$t('energySavings.reportingEndDate'), sortable: true },
          { key: 'totalPrediction', label: this.$t('energySavings.totalPrediction'), sortable: true },
          { key: 'totalActual', label: this.$t('energySavings.totalActual'), sortable: true },
          { key: 'totalLowerBound', label: this.$t('energySavings.totalLowerBound'), sortable: true },
          { key: 'totalUpperBound', label: this.$t('energySavings.totalUpperBound'), sortable: true },
          { key: 'actions', label: this.$t('common.actions'), sortable: false },
        ],
        page: 1,
        size: 25,
        sortBy: 'name',
        sortDesc: false,
      },
    };
  },
  computed: {
    ...mapState('energySavings', {
      analyses: (state) => state.analyses,
    }),
    processedAnalyses() {
      return this.analyses && this.analyses.length
        ? this.analyses.map((analysis) => {
            const totalPrediction = analysis.results.reduce((sum, result) => sum + result.prediction, 0);
            const totalActual = analysis.results.reduce((sum, result) => sum + result.actual, 0);
            const totalLowerBound = analysis.results.reduce((sum, result) => sum + result.lowerBound, 0);
            const totalUpperBound = analysis.results.reduce((sum, result) => sum + result.upperBound, 0);

            const reportingFrom = new Date(analysis.reportingFrom);
            const reportingTo = new Date(analysis.reportingTo);

            return {
              id: analysis.id,
              name: analysis.name,
              progress:Math.min((analysis.progress * 100).toFixed(0), 100),
              totalPrediction: totalPrediction.toFixed(2),
              totalActual: totalActual.toFixed(2),
              totalLowerBound: totalPrediction.toFixed() !== "0" ? "-" + (100 - ( totalLowerBound / totalPrediction ) *  100).toFixed() + "%" : "-100%",
              totalUpperBound: totalPrediction.toFixed() !== "0" ? "+" + (((totalUpperBound / totalPrediction) * 100) - 100).toFixed() + "%" : "-",
              reportingFrom: reportingFrom.toLocaleDateString("en-GB"),
              reportingTo: reportingTo.toLocaleDateString("en-GB"),
            };
          })
        : [];
    },
  },
  methods: {
    clearFilter() {
      this.table.filter = '';
    },
    refreshAnalyses() {
      this.$store.dispatch('energySavings/loadAnalysesForBuilding', this.building.id);
    },
    deleteAnalysis(analysis) {
      const analysisId = analysis.id;
      const buildingId = analysis.buildingId ?? this.building.id;
      let self = this;

      this.confirmDialog({
        text: this.$t('reporting.modal.confirmDeletion.text', { itemName: this.$t('energySavings.title') }),
        title: this.$t('reporting.modal.confirmDeletion.title'),
        callbackYes: () => {
          this.$store
            .dispatch('energySavings/deleteAnalysis', { analysisId, buildingId })
            .then(() => {
              if (self.successCallback) {
                self.successCallback();
              }
              self.$bvToast.toast(self.$t('reporting.modal.deletion.success', { itemName: this.$t('energySavings.title') } ), {
                title: self.$t('common.actions.ok'),
                variant: 'success',
                solid: true,
              });
            })
            .catch((error) => {
              console.error('Error deleting analysis:', error);
              self.$bvToast.toast(self.$t('common.modal.deletion.error', {itemName: this.$t('energySavings.title')}), {
                title: self.$t('common.popupTitle.warning'),
                variant: 'danger',
                solid: true,
              });
            })
            .finally(() => {
              setTimeout(() => {
                self.refreshAnalyses();
              }, 1000);
            });
        },
      });
      this.refreshAnalyses();
    },
    openAnalysisModal(analysis) {
      if (!analysis.id) {
        console.error('Analysis ID is undefined');
        return;
      }

      this.$store
        .dispatch('energySavings/fetchAnalysisById', { buildingId: this.building.id, analysisId: analysis.id })
        .then((fetchedAnalysis) => {
          if (fetchedAnalysis) {
            this.selectedAnalysis = fetchedAnalysis;
            this.showAnalysisModal = true;
          }
        })
        .catch((error) => {
          console.error('Failed to open analysis modal:', error);
        });
    },
    orderAnalysisSuccessCallback() {
      this.showModal = false;
      this.$emit('success-callback');
    },
  },
  created() {
    if (this.building) {
      this.refreshAnalyses();
    }
  },
};
</script>

<style scoped>
.summary-section {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
}

.plot-section {
  display: flex;
}

.plot-section h4 {
  text-align: center;
  width: 100%;
}

.plot-section highcharts {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.delete-link {
  cursor: pointer;
  color: #e94f37;
}

.icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
</style>
